<template>
  <div
    class="pc"
    :class="[curLanguage.default == 'EN' ? 'en_font' : 'cn_font']"
  >
    <div class="top-section">
      <div class="fix-content">
        <div :class="['hbg-btn', { active: expand }]" @click="expand = !expand">
          <div class="t-line"></div>
          <div class="m-line"></div>
          <div class="b-line"></div>
        </div>
        <div class="logo">
          <div @click="jumpUrl('https://www.bruceleeclub.com/zh-tw')">
            <img src="../../assets/images/logo.png" alt="" />
          </div>
          <div>
            <img src="../../assets/images/prizm_logo.png" alt="" />
          </div>
        </div>
        <div class="change-btn">
          <span @click="setLan('CN')">繁</span>
          <span class="line"></span>
          <span @click="setLan('EN')">EN</span>
        </div>
      </div>
      <div :class="['fix-menu', { active: expand }]">
        <div class="menu-content">
          <div class="content">
            <div
              class="s-item animated"
              :class="[{ fadeInUp: expand }, { active: menuActive == index }]"
              v-for="(item, index) in curLanguage.menuList"
              :key="index"
              :style="{ animationDelay: index * 0.2 + 0.1 + 's' }"
              @click="goPage(index)"
            >
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="page1">
            <video
              x5-video-player-type="h5-page"
              webkit-playsinline="true"
              playsinline="true"
              x5-video-orientation="landscape|portrait"
              src="https://test-h5-uat.prizmgz.com/static/media/Prizm_BruceLee_final_124mb.mp4"
              muted
              loop
              autoplay
              controls
              preload
              poster="../../assets/images/video_poster.png"
            ></video>
            <!-- <div class="lee-title">
              <img :src="curLanguage.exhibition.title" alt="" />
            </div> -->
          </div>
        </div>
        <!-- 展覽資訊 -->
        <div class="swiper-slide">
          <div class="page2">
            <div class="left-part">
              <div class="lee-top">
                <div class="lee-title">
                  <img :src="curLanguage.exhibition.title" alt="" />
                </div>
                <!-- <div class="title-wrap">
                <h3 class="title" v-html="curLanguage.exhibition.title"></h3>
                <h3 class="title2" v-html="curLanguage.exhibition.title"></h3>
              </div> -->
                <p
                  class="des"
                  v-html="curLanguage.exhibition.des"
                  :class="[
                    switchFont ? 'switch' : 'normal',
                    { en_xiao: curLanguage.default == 'EN' },
                  ]"
                ></p>
              </div>
              <div class="lee">
                <img src="../../assets/images/lee.png" alt="" />
              </div>
            </div>
            <div class="right-part">
              <div class="zhanlan">
                <div class="zl-top">
                  <h4 class="title" :class="[switchFont ? 'switch' : 'normal']">
                    {{ curLanguage.exhibition.detailList.title }}
                  </h4>
                  <div class="date" :class="[switchFont ? 'switch' : 'normal']">
                    <img :src="curLanguage.exhibition.detailList.date" alt="" />
                  </div>
                  <div
                    class="detail"
                    :class="[switchFont ? 'switch' : 'normal']"
                  >
                    <div class="location">
                      <div class="left">
                        {{ curLanguage.exhibition.detailList.location.title }}
                      </div>
                      <div class="right">
                        {{ curLanguage.exhibition.detailList.location.txt }}
                      </div>
                    </div>
                    <div class="time">
                      <div class="left">
                        {{ curLanguage.exhibition.detailList.actTime.title }}
                      </div>
                      <div class="right">
                        <div
                          class="time-item"
                          v-for="(item, index) in curLanguage.exhibition
                            .detailList.actTime.timeList"
                          :key="index"
                        >
                          <p>
                            <span>{{ item.date[0] }}</span>
                            <span class="line"></span>
                            <span>{{ item.date[1] }}</span>
                          </p>
                          <p>
                            <span>{{ item.time[0] }}</span>
                            <span class="line"></span>
                            <span>{{ item.time[1] }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="detail-list">
                    <div
                      class="detail-item"
                      v-for="(item, index) in curLanguage.exhibition.detailList
                        .list"
                      :key="index"
                    >
                      <div class="left">
                        <img :src="item.icon" alt="" />
                      </div>
                      <div class="right">
                        <p>{{ item.title }}</p>
                        <p>{{ item.txt }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="time-list">
                    <div class="time-title">
                      {{ curLanguage.exhibition.actTime.title }}
                    </div>
                    <div
                      class="time-item"
                      v-for="(item, index) in curLanguage.exhibition.actTime
                        .timeList"
                      :key="index"
                    >
                      <div class="left">{{ item.date }}</div>
                      <div class="right">{{ item.time }}</div>
                    </div>
                  </div> -->
                </div>
                <div class="my-map">
                  <div class="lee-map" @mousewheel.stop="() => {}">
                    <img :src="curLanguage.exhibition.map" alt="" />
                  </div>
                  <span class="zoom-icon" @click.stop="showMapPop = true">
                    <img
                      src="../../assets/images/svg/icon_zoom_in.svg"
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 故宮VR體驗 -->
        <div class="swiper-slide">
          <div class="page3">
            <div class="page3-content">
              <div class="page-top" :class="[switchFont ? 'switch' : 'normal']">
                <h3 class="title">
                  {{ curLanguage.VR.title }}
                </h3>
                <p class="des">
                  {{ curLanguage.VR.des }}
                </p>
              </div>
              <div class="page-bottom">
                <div class="swiper-wrap">
                  <div class="swiper-vr">
                    <div
                      :class="['swiper-item', { active: vrActive == index }]"
                      v-for="(item, index) in curLanguage.VR.bannerList"
                      :style="{
                        width:
                          vrActive == index
                            ? '48%'
                            : 52 / (curLanguage.VR.bannerList.length - 1) + '%',
                      }"
                      :key="index"
                      @click="vrActive = index"
                      @mouseenter="vrActive = index"
                    >
                      <img :src="item.img" alt="" />
                      <div class="swiper-des" v-show="vrActive == index">
                        <p>{{ item.des }}</p>
                      </div>
                    </div>
                    <div class="arrow-wrap">
                      <div class="left-arrow">
                        <img
                          src="../../assets/images/svg/arrow_left.svg"
                          alt=""
                        />
                      </div>
                      <div class="right-arrow">
                        <img
                          src="../../assets/images/svg/arrow_right.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="swiper-dot">
                    <div
                      :class="['dot', { active: vrActive == index }]"
                      v-for="(item, index) in curLanguage.VR.bannerList.length"
                      :key="index"
                      @click="vrActive = index"
                      @mouseenter="vrActive = index"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 展覽精華 -->
        <!-- <div class="swiper-slide">
          <div class="page4">
            <div class="page4-content">
              <div class="page-top" :class="[switchFont ? 'switch' : 'normal']">
                <h3 class="title">
                  {{ curLanguage.JH.title }}
                </h3>
                <p class="des">
                  {{ curLanguage.JH.des }}
                </p>
              </div>
              <div class="page-bottom">
                <div class="swiper-wrap">
                  <div class="swiper-jh">
                    <div
                      :class="['swiper-item', { active: jhActive == index }]"
                      v-for="(item, index) in curLanguage.JH.bannerList"
                      :style="{
                        width:
                          jhActive == index
                            ? '48%'
                            : 52 / (curLanguage.JH.bannerList.length - 1) + '%',
                      }"
                      :key="index"
                      @click="jhActive = index"
                      @mouseenter="jhActive = index"
                    >
                      <img :src="item.img" alt="" />
                      <div class="swiper-des" v-show="jhActive == index">
                        <p>{{ item.des }}</p>
                      </div>
                    </div>
                    <div class="arrow-wrap">
                      <div class="left-arrow">
                        <img
                          src="../../assets/images/svg/arrow_left.svg"
                          alt=""
                        />
                      </div>
                      <div class="right-arrow">
                        <img
                          src="../../assets/images/svg/arrow_right.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="swiper-dot">
                    <div
                      :class="['dot', { active: jhActive == index }]"
                      v-for="(item, index) in curLanguage.JH.bannerList.length"
                      :key="index"
                      @click="jhActive = index"
                      @mouseenter="jhActive = index"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- 聯絡我們 -->
        <div class="swiper-slide">
          <div class="page5">
            <div class="page5-content">
              <div class="page-top">
                <div
                  class="call-left"
                  :class="[switchFont ? 'switch' : 'normal']"
                >
                  <div class="call-txt">
                    <h3 class="title">{{ curLanguage.callUs.title }}</h3>
                    <p class="des" v-html="curLanguage.callUs.des"></p>
                  </div>
                </div>
                <div
                  class="call-right"
                  :class="[switchFont ? 'switch' : 'normal']"
                >
                  <div class="form-wrap">
                    <div class="name">
                      <p>{{ curLanguage.callUs.form.name.title }}</p>
                      <div class="name-wrap">
                        <div class="f-name">
                          <input
                            type="text"
                            :placeholder="curLanguage.callUs.form.name.zw[0]"
                            v-model="submitData.fName.text"
                            @blur="handleBlur(submitData.fName)"
                            @input="handleInput(submitData.fName)"
                          />
                          <p :class="['tip', { active: submitData.fName.mr }]">
                            {{
                              curLanguage.submitTip.fName.tip[
                                submitData.fName.tip.default
                              ]
                            }}
                          </p>
                        </div>
                        <div class="l-name">
                          <input
                            type="text"
                            :placeholder="curLanguage.callUs.form.name.zw[1]"
                            v-model="submitData.lName.text"
                            @blur="handleBlur(submitData.lName)"
                            @input="handleInput(submitData.lName)"
                          />
                          <p :class="['tip', { active: submitData.lName.mr }]">
                            {{
                              curLanguage.submitTip.lName.tip[
                                submitData.lName.tip.default
                              ]
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="country">
                      <p>{{ curLanguage.callUs.form.country.title }}</p>
                      <div
                        class="name-wrap"
                        @click.stop="showSelectPop = !showSelectPop"
                      >
                        <input
                          type="text"
                          :placeholder="curLanguage.callUs.form.country.zw"
                          v-model="submitData.country.text"
                          disabled
                        />
                        <p :class="['tip', { active: submitData.country.mr }]">
                          {{
                            curLanguage.submitTip.country.tip[
                              submitData.country.tip.default
                            ]
                          }}
                        </p>
                      </div>
                      <div class="country-wrap" @mousewheel.stop="">
                        <transition
                          enter-active-class="animated rotateInUpLeft"
                          leave-active-class="animated bounceOutRight"
                        >
                          <div class="country-list" v-if="showSelectPop">
                            <div>
                              <div
                                class="country-item"
                                v-for="(item, index) in curLanguage.countryList"
                                :key="index"
                                @click="selectItem(item)"
                              >
                                {{ item }}
                              </div>
                            </div>
                          </div>
                        </transition>
                      </div>
                    </div>
                    <div class="e-mail">
                      <p>{{ curLanguage.callUs.form.eMail.title }}</p>
                      <div class="name-wrap">
                        <input
                          type="email"
                          name=""
                          id=""
                          :placeholder="curLanguage.callUs.form.eMail.zw"
                          v-model="submitData.eMail.text"
                          @blur="handleBlur(submitData.eMail)"
                          @input="handleInput(submitData.eMail)"
                        />
                        <p :class="['tip', { active: submitData.eMail.mr }]">
                          {{
                            curLanguage.submitTip.eMail.tip[
                              submitData.eMail.tip.default
                            ]
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="sub-btn" @click.stop="handleSubmit()">
                      <span>{{ curLanguage.callUs.form.subBtn }}</span>
                      <div class="icon">
                        <img
                          src="../../assets/images/svg/icon_send.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="page-bottom">
                <div
                  class="about-us"
                  :class="[switchFont ? 'switch' : 'normal']"
                >
                  <div class="part-l">
                    <div class="img1">
                      <img :src="curLanguage.aboutUs.img1" alt="" />
                    </div>
                    <h4>{{ curLanguage.aboutUs.title1 }}</h4>
                    <p>
                      {{ curLanguage.aboutUs.des1 }}
                    </p>
                  </div>
                  <div class="part-m">
                    <div
                      class="img2"
                      @click="jumpUrl('https://www.prizmgroup.com/')"
                    >
                      <img :src="curLanguage.aboutUs.img2" alt="" />
                    </div>
                    <h4 v-html="curLanguage.aboutUs.title2"></h4>
                    <p v-html="curLanguage.aboutUs.des2"></p>
                  </div>
                  <div class="part-r">
                    <div class="support-us">
                      <h4>{{ curLanguage.aboutUs.title3 }}</h4>
                      <div
                        class="img3"
                        @click="jumpUrl('https://www.paymentasia.com/ct/')"
                      >
                        <img :src="curLanguage.aboutUs.img3" alt="" />
                      </div>
                    </div>
                    <div class="call-us">
                      <h4>{{ curLanguage.aboutUs.title4 }}</h4>
                      <p>
                        {{ curLanguage.aboutUs.des4 }}
                        <a :href="'mailto:' + curLanguage.aboutUs.email">{{
                          curLanguage.aboutUs.email
                        }}</a>
                      </p>
                    </div>
                    <div class="share-list">
                      <div
                        v-for="(item, index) in curLanguage.aboutUs.iconList"
                        :key="index"
                        @click="jumpUrl(item.url)"
                      >
                        <img :src="item.icon" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="banq">
                  <div v-html="curLanguage.banq"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <transition
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
      >
        <div class="mask" v-if="showMapPop"></div
      ></transition>

      <mappop
        :img="curLanguage.exhibition.map"
        :showMapPop="showMapPop"
        @closeMapPop="showMapPop = false"
      ></mappop>
    </div>
    <div
      :class="['subscribe', { active: !showBack }]"
      @click="goPage(curLanguage.menuList.length - 1)"
    >
      <div class="animated pulse infinite">
        <img :src="curLanguage.floatImg" alt="" />
      </div>
    </div>
    <div :class="['back-top', { active: showBack }]" @click="goPage(-1)">
      <img src="../../assets/images/svg/icon_back_to_top.svg" alt="" />
    </div>
    <cookiepop
      width="40%"
      padding="35px"
      btnWidth="28%"
      :cookieTxt="curLanguage.cookieTxt"
    ></cookiepop>
    <feedback
      width="30%"
      padding="35px"
      btnWidth="30%"
      :showPop="showFeedBackPop"
      :networkTip="curLanguage.networkTip"
      @close="showFeedBackPop = false"
    ></feedback>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import cookiepop from "@/components/cookiepop.vue";
import feedback from "@/components/feedback.vue";
import mappop from "@/components/mappop.vue";
import { keepUser } from "@/request/api";
import { getParams } from "@/tool/tool";

export default {
  name: "pc",
  data() {
    return {
      switchFont: false,
      showSelectPop: false,
      showFeedBackPop: false,
      expand: false,
      menuActive: -1,
      vrActive: 0,
      jhActive: 0,
      mySwiper: null,
      showBack: false,
      submitData: {
        fName: {
          text: "",
          tip: {
            default: "kong",
          },
          mr: false,
        },
        lName: {
          text: "",
          tip: {
            default: "kong",
          },
          mr: false,
        },
        country: {
          type: "country",
          text: "",
          tip: {
            default: "kong",
          },
          mr: false,
        },
        eMail: {
          type: "email",
          text: "",
          tip: {
            default: "kong",
          },
          mr: false,
        },
      },
      clickFlag: false,
      showMapPop: false,
      initialSlide: 0,
    };
  },
  computed: {
    ...mapGetters(["curLanguage", "fillTable"]),
  },
  created() {
    if (this.fillTable == "true") {
      this.initialSlide = this.curLanguage.menuList.length;
      this.menuActive = this.curLanguage.menuList.length;
      this.showBack = true;
    }
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    initSwiper() {
      this.mySwiper = new Swiper(".swiper", {
        // autoplay: true,
        initialSlide: this.initialSlide,
        loop: false,
        speed: 800,
        shortSwipes: false,
        longSwipesMs: 100,
        longSwipesRatio: 0.15,
        direction: "vertical",
        mousewheel: {
          forceToAxis: true,
          sensitivity: 0.1,
          // releaseOnEdges: true,
          thresholdDelta: 80,
        },
        effect: "creative",
        creativeEffect: {
          prev: {
            origin: "top center",
            translate: [0, "0%", 0],
            rotate: [0, 0, 0],
            scale: 1.05,
          },
          next: {
            origin: "center center",
            translate: [0, "100%", 0],
            scale: 1,
            rotate: [0, 0, 0],
          },
        },
        slideNextClass: "my-slide-next",
        on: {
          slideChangeTransitionEnd: () => {
            // this.menuActive = this.mySwiper.activeIndex - 1;
          },
          // progress: function (swiper, progress) {
          //   console.log(progress);
          // },
          slideChange: () => {
            if (!this.mySwiper) return;
            this.menuActive = this.mySwiper.activeIndex - 1;
            if (this.mySwiper.activeIndex == this.curLanguage.menuList.length) {
              this.showBack = true;
            } else {
              this.showBack = false;
            }
          },
        },
      });
    },
    // 跳转外链
    jumpUrl(url) {
      if (url.trim() == "") return;
      window.location.href = url;
    },
    handleBlur(item) {
      if (item.text.trim() == "") {
        item.mr = true;
        return false;
      }
      return true;
    },
    handleInput(item) {
      let reg = /^[\w.%+-]+@[\w.%+-]+(\.[\w-]+)+$/;
      let nameReg = /(^([a-zA-Z\u4e00-\u9fa5]+\s)*[a-zA-Z\u4e00-\u9fa5]+$)/;
      if (item.text.trim() == "") {
        item.tip.default = "kong";

        item.text = "";
        item.mr = true;
        return false;
      } else {
        if (item.type == "email") {
          if (!reg.test(item.text)) {
            item.mr = true;
            item.tip.default = "geshi";
            return false;
          } else {
            item.mr = false;
            return true;
          }
        } else {
          if (!nameReg.test(item.text) && item.type != "country") {
            item.mr = true;
            item.tip.default = "geshi";
            return false;
          } else {
            item.mr = false;
            return true;
          }
        }
      }
    },
    selectItem(item) {
      this.submitData.country.text = item;
      this.handleInput(this.submitData.country);
      this.showSelectPop = false;
    },
    handleSubmit() {
      let arr = [];
      for (let key in this.submitData) {
        arr.push(this.handleInput(this.submitData[key]));
      }
      if (
        arr.some((item) => {
          return item == false;
        })
      )
        return;
      if (this.clickFlag) return;
      this.clickFlag = true;
      let { fName, lName, eMail, country } = this.submitData;
      let data = {
        surname: fName.text,
        name: lName.text,
        email: eMail.text,
        country: country.text,
      };
      keepUser(data).then(
        (res) => {
          this.clickFlag = false;
          this.showFeedBackPop = true;
        },
        (err) => {
          this.clickFlag = false;
        }
      );
    },
    ...mapMutations(["setLanguage"]),
    // 设置语言
    setLan(lan) {
      if (lan == this.curLanguage.default) return;
      this.switchFont = true;
      setTimeout(() => {
        this.switchFont = false;
        this.setLanguage(lan);
      }, 200);
    },
    // 跳转page
    goPage(num) {
      if (!this.mySwiper) return;
      this.mySwiper.slideTo(num + 1, 600, true);
      this.menuActive = num;
      this.expand = false;
    },
  },
  components: { cookiepop, mappop, feedback },
};
</script>

<style lang="scss" scoped>
@import "./pc.scss";
</style>