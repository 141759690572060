import Vue from 'vue'
import VueRouter from 'vue-router'
import { isDIYMobile } from "../tool/tool"
import login from '../views/login/login.vue'
import mobile from '../views/mobile/mobile.vue'
import pc from '../views/pc/pc.vue'
import page404 from '../views/page404/page404.vue'

import env from "@/environment/env";
import store from "../store";

Vue.use(VueRouter)

const uatRoutes = [
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/',
    name: 'home',
    meta: {
      isNeedLogin: true,
    },
    component: isDIYMobile() ? mobile : pc
  },
  {
    path: '*',
    name: 'page404',
    component: page404,
  }
]

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      isNeedLogin: false,
    },
    component: isDIYMobile() ? mobile : pc
  },
  {
    path: '*',
    name: 'page404',
    component: page404,
  }
]

const router = new VueRouter({
  mode: "hash",
  routes: env.uat ? uatRoutes : routes
})

router.beforeEach((to, from, next) => {
  // 判断是否需要登陆
  if (to.meta.isNeedLogin) {
    if (store.getters.isLogin) {
      next()
    } else {
      next("/login")
    }
    return;
  }
  next()
})

export default router
