<template>
  <div
    class="mobile-page"
    ref="mobile"
    @scroll="handleScroll"
    :class="[curLanguage.default == 'EN' ? 'en_font' : 'cn_font']"
  >
    <div class="top-section" ref="topSection">
      <div class="fix-content">
        <div :class="['hbg-btn', { active: expand }]" @click="expand = !expand">
          <div class="t-line"></div>
          <div class="m-line"></div>
          <div class="b-line"></div>
        </div>
        <div class="logo">
          <div @click="jumpUrl('https://www.bruceleeclub.com/zh-tw')">
            <img src="../../assets/images/logo.png" alt="" />
          </div>
          <div>
            <img src="../../assets/images/prizm_logo.png" alt="" />
          </div>
        </div>
        <div class="change-btn">
          <span @click="setLan('CN')">繁</span>
          <span class="line"></span>
          <span @click="setLan('EN')">EN</span>
        </div>
      </div>
      <div :class="['fix-menu', { active: expand }]">
        <div class="menu-content">
          <div class="content">
            <div
              class="s-item animated"
              :class="[{ fadeInUp: expand }, { active: menuActive == index }]"
              v-for="(item, index) in curLanguage.menuList"
              :key="index"
              :style="{ animationDelay: index * 0.2 + 0.1 + 's' }"
            >
              <span
                @click="
                  scrollTo(sectionScope[index] && sectionScope[index].min)
                "
                :class="[switchFont ? 'switch' : 'normal']"
                >{{ item }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="section">
      <div class="section-wrap">
        <div class="section-1">
          <!-- <h1 class="title">李小龍50週年紀念展覽</h1> -->
          <div class="video">
            <img src="../../assets/images/video_poster.png" alt="" />
            <video
              id="my_video"
              src="https://test-h5-uat.prizmgz.com/static/media/Prizm_BruceLee_final_124mb.mp4"
              x5-video-player-type="h5-page"
              webkit-playsinline="true"
              playsinline="true"
              x5-video-orientation="landscape|portrait"
              muted
              loop
              controls
              autoplay
              preload
              poster="../../assets/images/video_poster.png"
            ></video>
          </div>
          <!-- <h3 class="se-title">「生活要快樂，但絕不要安於現狀。」</h3> -->
        </div>
      </div>
    </section>
    <!-- 展覽資訊 -->
    <section class="section">
      <div class="section-wrap">
        <div class="section-2">
          <div class="one" :class="[switchFont ? 'switch' : 'normal']">
            <div class="con">
              <div class="lee-title">
                <img :src="curLanguage.exhibition.title" alt="" />
              </div>
              <!-- <div class="title-wrap">
                <h3 class="title" v-html="curLanguage.exhibition.title">
                </h3>
                <h3 class="title2" v-html="curLanguage.exhibition.title"></h3>
              </div> -->
              <p v-html="curLanguage.exhibition.des"></p>
            </div>
          </div>
          <div class="two">
            <img src="../../assets/images/lee.png" alt="" />
          </div>
          <div class="four scroll-section">
            <h4 class="title" :class="[switchFont ? 'switch' : 'normal']">
              {{ curLanguage.exhibition.detailList.title }}
            </h4>
            <div class="date" :class="[switchFont ? 'switch' : 'normal']">
              <img :src="curLanguage.exhibition.detailList.date" alt="" />
            </div>
            <div class="detail" :class="[switchFont ? 'switch' : 'normal']">
              <div class="location">
                <div class="left">
                  {{ curLanguage.exhibition.detailList.location.title }}
                </div>
                <div class="arrow"></div>
                <div class="right">
                  {{ curLanguage.exhibition.detailList.location.txt }}
                </div>
              </div>
              <div class="time">
                <div class="left">
                  {{ curLanguage.exhibition.detailList.actTime.title }}
                </div>
                <div class="arrow"></div>
                <div class="right">
                  <div
                    class="time-item"
                    v-for="(item, index) in curLanguage.exhibition.detailList
                      .actTime.timeList"
                    :key="index"
                  >
                    <p>
                      <span>{{ item.date[0] }}</span>
                      <span class="line"></span>
                      <span>{{ item.date[1] }}</span>
                    </p>
                    <p>
                      <span>{{ item.time[0] }}</span>
                      <span class="line"></span>
                      <span>{{ item.time[1] }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div
              class="detail-list"
              :class="[switchFont ? 'switch' : 'normal']"
            >
              <div
                class="detail-item"
                v-for="(item, index) in curLanguage.exhibition.detailList.list"
                :key="index"
              >
                <div class="left">
                  <img :src="item.icon" alt="" />
                </div>
                <div class="right">
                  <div>
                    <p>{{ item.title }}</p>
                    <p>{{ item.txt }}</p>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <div class="time-list" :class="[switchFont ? 'switch' : 'normal']">
              <div class="time-title">
                {{ curLanguage.exhibition.actTime.title }}
              </div>
              <div
                class="time-item"
                v-for="(item, index) in curLanguage.exhibition.actTime.timeList"
                :key="index"
              >
                <div class="left">{{ item.date }}</div>
                <div class="right">{{ item.time }}</div>
              </div>
            </div> -->
          </div>
          <div
            class="three"
            :style="{ height: mapHeight ? mapHeight + 'px' : 'auto' }"
          >
            <div class="lee-map" @click="zoomIn">
              <img
                ref="map"
                :style="{ width: defaultWidth + '%' }"
                :src="curLanguage.exhibition.map"
                alt=""
              />
            </div>
            <span class="zoom-icon" @click.self="zoomOut">
              <img src="../../assets/images/svg/icon_zoom_in.svg" alt="" />
            </span>
            <span class="zoom-icon zoom_out" @click.self="zoomIn">
              <img src="../../assets/images/svg/icon_zoom_out.svg" alt="" />
            </span>
          </div>
        </div>
      </div>
    </section>
    <!-- 故宮VR體驗 -->
    <section class="section scroll-section">
      <div class="section-wrap">
        <div class="section-3 common-section">
          <h3 class="title" :class="[switchFont ? 'switch' : 'normal']">
            {{ curLanguage.VR.title }}
          </h3>
          <p class="des" :class="[switchFont ? 'switch' : 'normal']">
            {{ curLanguage.VR.des }}
          </p>
          <div class="swiper-com" :key="curLanguage.default">
            <div class="swiper-vr">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in curLanguage.VR.bannerList"
                  :key="index"
                >
                  <div class="img">
                    <img :src="item.img" alt="" />
                  </div>
                  <div class="banner-des">
                    <p>
                      {{ item.des }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-prev-vr">
              <img src="../../assets/images/svg/arrow_left.svg" alt="" />
            </div>
            <div class="swiper-next-vr">
              <img src="../../assets/images/svg/arrow_right.svg" alt="" />
            </div>
            <div class="swiper-pagination-vr"></div>
          </div>
        </div>
      </div>
    </section>
    <!-- 展覽精華 -->
    <!-- <section class="section scroll-section">
      <div class="section-wrap">
        <div class="section-4 common-section">
          <h3 class="title" :class="[switchFont ? 'switch' : 'normal']">
            {{ curLanguage.JH.title }}
          </h3>
          <p class="des" :class="[switchFont ? 'switch' : 'normal']">
            {{ curLanguage.JH.des }}
          </p>
          <div class="swiper-com" :key="curLanguage.default">
            <div class="swiper-jh">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in curLanguage.JH.bannerList"
                  :key="index"
                >
                  <div class="img">
                    <img :src="item.img" alt="" />
                  </div>
                  <div class="banner-des">
                    <p>
                      {{ item.des }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-prev-jh">
              <img src="../../assets/images/svg/arrow_left.svg" alt="" />
            </div>
            <div class="swiper-next-jh">
              <img src="../../assets/images/svg/arrow_right.svg" alt="" />
            </div>
            <div class="swiper-pagination-jh"></div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- 聯絡我們 -->
    <section class="section scroll-section">
      <div class="section-wrap">
        <div class="section-5 common-section">
          <h3 class="title" :class="[switchFont ? 'switch' : 'normal']">
            {{ curLanguage.callUs.title }}
          </h3>
          <p
            class="des"
            v-html="curLanguage.callUs.des"
            :class="[switchFont ? 'switch' : 'normal']"
          ></p>
          <div class="form-wrap" :class="[switchFont ? 'switch' : 'normal']">
            <div class="name">
              <p>{{ curLanguage.callUs.form.name.title }}</p>
              <div class="name-wrap">
                <div class="f-name">
                  <input
                    type="text"
                    :placeholder="curLanguage.callUs.form.name.zw[0]"
                    v-model="submitData.fName.text"
                    @blur="handleBlur(submitData.fName)"
                    @input="handleInput(submitData.fName)"
                  />
                  <p :class="['tip', { active: submitData.fName.mr }]">
                    {{
                      curLanguage.submitTip.fName.tip[
                        submitData.fName.tip.default
                      ]
                    }}
                  </p>
                </div>
                <div class="l-name">
                  <input
                    type="text"
                    :placeholder="curLanguage.callUs.form.name.zw[1]"
                    v-model="submitData.lName.text"
                    @blur="handleBlur(submitData.lName)"
                    @input="handleInput(submitData.lName)"
                  />
                  <p :class="['tip', { active: submitData.lName.mr }]">
                    {{
                      curLanguage.submitTip.lName.tip[
                        submitData.lName.tip.default
                      ]
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="country">
              <p>{{ curLanguage.callUs.form.country.title }}</p>
              <div class="name-wrap" @click="showSelectPop = true">
                <input
                  type="text"
                  :placeholder="curLanguage.callUs.form.country.zw"
                  v-model="submitData.country.text"
                  disabled
                />
                <p :class="['tip', { active: submitData.country.mr }]">
                  {{
                    curLanguage.submitTip.country.tip[
                      submitData.country.tip.default
                    ]
                  }}
                </p>
              </div>
            </div>
            <div class="e-mail">
              <p>{{ curLanguage.callUs.form.eMail.title }}</p>
              <div class="name-wrap">
                <input
                  type="email"
                  name=""
                  id=""
                  :placeholder="curLanguage.callUs.form.eMail.zw"
                  v-model="submitData.eMail.text"
                  @blur="handleBlur(submitData.eMail)"
                  @input="handleInput(submitData.eMail)"
                />
                <p :class="['tip', { active: submitData.eMail.mr }]">
                  {{
                    curLanguage.submitTip.eMail.tip[
                      submitData.eMail.tip.default
                    ]
                  }}
                </p>
              </div>
            </div>
            <div class="sub-btn" @click="handleSubmit()">
              <span>{{ curLanguage.callUs.form.subBtn }}</span>
              <div class="icon">
                <img src="../../assets/images/svg/icon_send.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section scroll-section">
      <div class="section-wrap">
        <div class="section-6 common-section">
          <div class="com-content" :class="[switchFont ? 'switch' : 'normal']">
            <div class="about-us">
              <div class="img1">
                <img :src="curLanguage.aboutUs.img1" alt="" />
              </div>
              <h4>{{ curLanguage.aboutUs.title1 }}</h4>
              <p>
                {{ curLanguage.aboutUs.des1 }}
              </p>
            </div>
            <div class="prizm-us">
              <div class="img2" @click="jumpUrl('https://www.prizmgroup.com/')">
                <img :src="curLanguage.aboutUs.img2" alt="" />
              </div>
              <h4 v-html="curLanguage.aboutUs.title2"></h4>
              <p v-html="curLanguage.aboutUs.des2"></p>
            </div>
            <div class="support-us">
              <h4>{{ curLanguage.aboutUs.title3 }}</h4>
              <div
                class="img3"
                @click="jumpUrl('https://www.paymentasia.com/ct/')"
              >
                <img :src="curLanguage.aboutUs.img3" alt="" />
              </div>
            </div>
            <div class="call-us">
              <h4>{{ curLanguage.aboutUs.title4 }}</h4>
              <p>
                {{ curLanguage.aboutUs.des4 }}
                <a :href="'mailto:' + curLanguage.aboutUs.email">{{
                  curLanguage.aboutUs.email
                }}</a>
              </p>
            </div>
            <div class="share-list">
              <div
                v-for="(item, index) in curLanguage.aboutUs.iconList"
                :key="index"
                @click="jumpUrl(item.url)"
              >
                <img :src="item.icon" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="banq" v-html="curLanguage.banq"></div>

    <div
      :class="['subscribe', { active: !showBack }]"
      @click="
        scrollTo(
          sectionScope[sectionScope.length - 2] &&
            sectionScope[sectionScope.length - 2].min
        )
      "
    >
      <div class="animated pulse infinite">
        <img :src="curLanguage.floatImg" alt="" />
      </div>
    </div>
    <div :class="['back-top', { active: showBack }]" @click="scrollTo(0)">
      <img src="../../assets/images/svg/icon_back_to_top.svg" alt="" />
    </div>

    <cookiepop :cookieTxt="curLanguage.cookieTxt"></cookiepop>
    <selectpop
      :showSelectPop="showSelectPop"
      :countryList="curLanguage.countryList"
      :btnTxt="curLanguage.btnTxt"
      @closePop="showSelectPop = false"
      @selectItem="selectItem"
    ></selectpop>
    <feedback
      :showPop="showFeedBackPop"
      :networkTip="curLanguage.networkTip"
      @close="showFeedBackPop = false"
    ></feedback>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import cookiepop from "@/components/cookiepop.vue";
import feedback from "@/components/feedback.vue";
import selectpop from "@/components/selectpop.vue";
import { keepUser } from "@/request/api";
import { picSource, videoSource } from "@/resource";
import { preLoadResource } from "@/tool/tool";

export default {
  name: "mobile",
  data() {
    return {
      switchFont: false,
      expand: false,
      menuActive: 0,
      sectionScope: [],
      showBack: false,
      submitData: {
        fName: {
          text: "",
          tip: {
            default: "kong",
          },
          mr: false,
        },
        lName: {
          text: "",
          tip: {
            default: "kong",
          },
          mr: false,
        },
        country: {
          type: "country",
          text: "",
          tip: {
            default: "kong",
          },
          mr: false,
        },
        eMail: {
          type: "email",
          text: "",
          tip: {
            default: "kong",
          },
          mr: false,
        },
      },
      clickFlag: false,
      mapHeight: 0,
      defaultWidth: 100,
      swiperVR: null,
      swiperJH: null,
      showSelectPop: false,
      showFeedBackPop: false,
    };
  },
  computed: {
    ...mapGetters(["curLanguage", "fillTable"]),
  },
  mounted() {
    // ScrollReveal().reveal(document.querySelectorAll(".scroll-section"), {
    //   distance: "300px",
    //   duration: 1000,
    //   origin: "bottom",
    //   cleanup: true,
    //   opacity: 0,
    //   container: this.$refs.mobile,
    // });
    this.initSwiper();
    preLoadResource(
      [require("../../assets/images/image_map.png")],
      [],
      () => {},
      () => {
        this.$nextTick(() => {
          this.mapHeight = this.$refs.map.offsetHeight;
        });
      }
    );
    if (this.fillTable == "true") {
      this.$toast.loading({
        message: "资源加载中",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
    }
    preLoadResource(
      picSource,
      [],
      // videoSource,
      () => {},
      () => {
        this.$toast.clear();
        this.getSectionScope();
        this.$nextTick(() => {
          this.mapHeight = this.$refs.map.offsetHeight;
          if (this.fillTable == "true") {
            this.scrollTo(this.sectionScope[2].min);
          }
        });
      }
    );
    window.addEventListener("resize", () => {
      this.mapHeight = this.$refs.map.offsetHeight;
      this.getSectionScope();
    });
    document.addEventListener(
      "WeixinJSBridgeReady",
      function () {
        let myVideo = document.getElementById("my_video");
        myVideo.oncanplay = function () {
          myVideo.play();
        };
      },
      false
    );
  },
  methods: {
    zoomIn() {
      if (!this.mapHeight) return;
      if (this.defaultWidth > 100) {
        this.defaultWidth -= 20;
      }
    },
    zoomOut() {
      if (!this.mapHeight) return;
      if (this.defaultWidth >= 200) return;
      this.defaultWidth += 20;
    },
    // 跳转外链
    jumpUrl(url) {
      if (url.trim() == "") return;
      window.location.href = url;
    },
    handleBlur(item) {
      if (item.text.trim() == "") {
        item.mr = true;
        return false;
      }
      return true;
    },
    handleInput(item) {
      let reg = /^[\w.%+-]+@[\w.%+-]+(\.[\w-]+)+$/;
      let nameReg = /(^([a-zA-Z\u4e00-\u9fa5]+\s)*[a-zA-Z\u4e00-\u9fa5]+$)/;
      if (item.text.trim() == "") {
        item.tip.default = "kong";
        item.text = "";
        item.mr = true;
        return false;
      } else {
        if (item.type == "email") {
          if (!reg.test(item.text)) {
            item.mr = true;
            item.tip.default = "geshi";
            return false;
          } else {
            item.mr = false;
            return true;
          }
        } else {
          if (!nameReg.test(item.text) && item.type != "country") {
            item.mr = true;
            item.tip.default = "geshi";
            return false;
          } else {
            item.mr = false;
            return true;
          }
        }
      }
    },
    selectItem(value) {
      this.submitData.country.text = value;
      this.handleInput(this.submitData.country);
      this.showSelectPop = false;
    },
    handleSubmit() {
      let arr = [];
      for (let key in this.submitData) {
        arr.push(this.handleInput(this.submitData[key]));
      }
      if (
        arr.some((item) => {
          return item == false;
        })
      )
        return;
      if (this.clickFlag) return;
      this.clickFlag = true;
      let { fName, lName, eMail, country } = this.submitData;
      let data = {
        surname: fName.text,
        name: lName.text,
        email: eMail.text,
        country: country.text,
      };
      keepUser(data).then(
        (res) => {
          this.clickFlag = false;
          this.showFeedBackPop = true;
        },
        (err) => {
          this.clickFlag = false;
        }
      );
    },
    ...mapMutations(["setLanguage"]),
    // 获取滚动范围
    getSectionScope() {
      let scrollSection = document.querySelectorAll(".scroll-section");
      this.$nextTick(() => {
        this.sectionScope = [];
        scrollSection.forEach((el, index) => {
          this.sectionScope.push({
            min:
              el.offsetTop -
              Math.floor(this.$refs.topSection.offsetHeight - 1.5),
            max:
              el.offsetTop -
              this.$refs.topSection.offsetHeight +
              el.offsetHeight,
          });
        });
      });
    },
    // 设置语言
    setLan(lan) {
      if (lan == this.curLanguage.default) return;
      this.switchFont = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.switchFont = false;
          this.setLanguage(lan);
          preLoadResource(
            [require("../../assets/images/pic1.png")],
            [],
            () => {},
            () => {
              this.$nextTick(() => {
                this.getSectionScope();
              });
            }
          );

          this.$nextTick(() => {
            this.initSwiper();
          });
        }, 200);
      });
    },
    // 初始化轮播
    initSwiper() {
      this.swiperVR = new Swiper(".swiper-vr", {
        autoplay: {
          disableOnInteraction: false,
          delay: 3000,
        },
        loop: true,
        navigation: {
          prevEl: ".swiper-prev-vr",
          nextEl: ".swiper-next-vr",
        },
        pagination: {
          el: ".swiper-pagination-vr",
          bulletElement: "div",
          bulletClass: "my-bullet",
          bulletActiveClass: "my-bullet-active",
        },
      });
      this.swiperJH = new Swiper(".swiper-jh", {
        autoplay: {
          disableOnInteraction: false,
          delay: 4000,
        },
        loop: true,
        navigation: {
          prevEl: ".swiper-prev-jh",
          nextEl: ".swiper-next-jh",
        },
        pagination: {
          el: ".swiper-pagination-jh",
          bulletElement: "div",
          bulletClass: "my-bullet",
          bulletActiveClass: "my-bullet-active",
        },
      });
    },
    // 滚动active
    handleScroll(ev) {
      if (this.sectionScope.length <= 0) return;
      this.sectionScope.forEach((item, index) => {
        if (
          this.$refs.mobile.scrollTop >= item.min &&
          this.$refs.mobile.scrollTop < item.max
        ) {
          this.menuActive = index;
        }
      });
      if (this.$refs.mobile.scrollTop >= this.sectionScope[2].min) {
        if (this.showBack) return;
        this.showBack = true;
      } else {
        if (!this.showBack) return;
        this.showBack = false;
      }
    },
    // 滚动到指定处
    scrollTo(num) {
      if (isNaN(num)) return;
      this.$refs.mobile.scrollTo({
        top: num,
        behavior: "smooth",
      });
      this.expand = false;
    },
  },
  components: { cookiepop, selectpop, feedback },
};
</script>

<style lang="scss" scoped>
@import "./mobile.scss";
</style>
