<template>
  <transition
    enter-active-class="animated rollIn"
    leave-active-class="animated bounceOutRight"
  >
    <div class="map-pop" v-if="showMapPop">
      <div class="img-wrap">
        <img :src="img" alt="" />
        <div class="close-btn" @click="closeMapPop()"></div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "mappop",
  props: {
    img: {
      type: String,
      default: require("../assets/images/image_map.png"),
    },
    showMapPop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    closeMapPop() {
      this.$emit("closeMapPop", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.map-pop {
  animation-duration: 0.3s;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  .img-wrap {
    position: relative;
    width: 60%;
    padding: 20px;
    background-color: #fff;
    .close-btn {
      cursor: pointer;
      position: absolute;
      right: 30px;
      top: 40px;
      width: 30px;
      height: 2px;
      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}
</style>