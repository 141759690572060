import axios from 'axios';
import { Toast } from 'vant';
import { Notify } from 'vant';
import env from "@/environment/env";
const request = axios.create({
    baseURL: process.env.NODE_ENV == 'production' ? env.requestHostName : '/lee',
    timeout: 6000,
    load: false, // 该请求是否需要展示loading面板
    reNum: 1,
    reDelay: 2000
})


request.interceptors.request.use((config) => {
    if (config.load) {
        Toast.loading({
            message: '',
            forbidClick: true,
            loadingType: 'spinner',
            duration: 0
        });
    }

    // config.cancelToken = new axios.CancelToken((cancelFn) => {
    //     Vue.prototype.$cancelFnArr = Vue.prototype.$cancelFnArr ? Vue.prototype.$cancelFnArr : [];
    //     Vue.prototype.$cancelFnArr.push(() => {
    //         config.isCancel = true;
    //         cancelFn("取消请求");
    //     })
    // })

    return config;
}, (error) => {
    return Promise.reject("请求拦截出错：" + error);
})

request.interceptors.response.use((res) => {
    Toast.clear();
    return res.data;
}, (error) => {
    Toast.clear();
    let { response, config } = error;
    // console.dir(error)
    if (response) {
        switch (response.status) {
            case 401://权限问题,提示未登录或无权限等；
                break;
            case 403://服务器拒绝执行 （token过期）
                break;
            case 404://找不到页面
                break;
            case 500://找不到页面
                console.log("服务器问题～")
                break;
        }
        return Promise.reject("响应拦截出错：" + error);
    } else {
        //服务器连结果都没有返回
        return new Promise((resolve, reject) => {

            setTimeout(() => {
                //断网处理：跳转断网页面/提示网络错误等等
                if (!window.navigator.onLine) {

                    return Promise.reject("响应拦截出错：网络错误");
                }

                // 取消请求（什么都不用返回）
                if (!config) return;

                // 请求超时
                Notify({ type: 'danger', message: error.message });
                if (--config.reNum > 0) {
                    config.impApi = true
                    Notify({ type: 'danger', message: `${config.reDelay / 1000}秒后将重新连接，请检查网络状态` });
                    setTimeout(() => {
                        if (config.isCancel) {
                            Notify.clear();
                            return;
                        }
                        request(config).then((data) => {
                            resolve(data)
                        }, (err) => {
                            reject(err)
                        })
                    }, config.reDelay)
                } else {
                    if (config.reNum == 0 && config.impApi) {
                        Notify({ type: 'danger', message: "建议检查网络并重启程序" });
                        reject("请求重连也无法挽回：" + error);
                    } else {
                        reject(error);
                    }
                }

            }, 200)

        })
    }

})

export default request