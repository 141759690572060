const envSet = {
    uat: {
        test: true,
        hostName: "test-h5-uat.prizmgz.com",
        requestHostName: "https://test-api-uat.prizmgz.com"
    },
    test: {
        test: true,
        hostName: "test-h5.prizmgz.com",
        requestHostName: "https://test-h5.prizmgz.com"
    },
    pro: {
        hostName: "www.bruceleeverse.com",
        requestHostName: "https://www.bruceleeverse.com"
    }
}
const curHostName = window.location.hostname; // 可以修改该处切换环境
// 默认环境
let curEnv = {
    uat: true,
    hostName: location.hostname,
    requestHostName: location.origin
};

for (let key in envSet) {
    let item = envSet[key]
    if (item.hostName == curHostName) {
        let { hostName, requestHostName } = item;
        let uat = !!item.test;
        curEnv = {
            uat, hostName, requestHostName
        }
    }
}
console.log("当前环境:", curEnv)

export default curEnv;