<template>
  <div class="cookie" v-if="showPop">
    <div class="cookie-pop"></div>
    <div class="pop-wrap" :style="{ width: width, padding: padding }">
      <p class="txt">
        {{ cookieTxt.tip }}
      </p>
      <div
        class="agree-btn"
        :style="{ width: btnWidth }"
        @click="showPop = false"
      >
        {{ cookieTxt.btnTxt }}
      </div>
      <div class="close-btn" @click="showPop = false"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "90%",
    },
    padding: {
      type: String,
      default: "20px",
    },
    btnWidth: {
      type: String,
      default: "50%",
    },
    cookieTxt: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      showPop: false,
    };
  },
  created() {
    this.showCookiePop();
  },
  methods: {
    showCookiePop() {
      let time = localStorage.getItem("time");
      let nowDate = new Date();
      nowDate.setHours(0);
      nowDate.setMinutes(0);
      nowDate.setSeconds(0);
      nowDate.setMilliseconds(0);
      if (!time) {
        this.showPop = true;
        localStorage.setItem("time", nowDate.getTime());
      } else {
        if (time == nowDate.getTime()) {
          this.showPop = false;
        } else {
          this.showPop = true;
          localStorage.setItem("time", nowDate.getTime());
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie-pop {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  pointer-events: none;
}
.pop-wrap {
  position: fixed;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px #ccc inset;
  //   border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  .txt {
    text-align: center;
    font-size: 16px;
    color: #878787;
    line-height: 30px;
    letter-spacing: 1px;
  }
  .agree-btn {
    cursor: pointer;
    width: 50%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffa130;
    // border-radius: 10px;
    color: #fff;
    margin: 20px auto 0;
    letter-spacing: 1px;
  }

  .close-btn {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-30px);
    width: 30px;
    height: 30px;
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 2px;
      background-color: #fff;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
}
</style>