<template>
  <div class="login">
    <div class="login-form">
      <div class="name">
        <!-- <span>帳號</span> -->
        <!-- placeholder="請輸入帳號" -->
        <div>
          <input
            type="text"
            v-model="loginForm.name"
            @focus="active1 = true"
            @blur="loginForm.name == '' ? (active1 = false) : ''"
          />
          <span :class="[{ active: active1 }]">請輸入帳號</span>
        </div>
      </div>
      <div class="password">
        <!-- <span>密碼</span> -->
        <!-- placeholder="請輸入密碼" -->
        <div>
          <input
            type="text"
            @focus="active2 = true"
            @blur="loginForm.password == '' ? (active2 = false) : ''"
            v-model="loginForm.password"
          />
          <span :class="[{ active: active2 }]">請輸入密碼</span>
        </div>
      </div>
      <div class="sub-btn" @click="handleSubmit">
        <span>登入</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import md5 from "js-md5";
export default {
  name: "login",
  data() {
    return {
      loginForm: {
        name: "",
        password: "",
      },
      confirm: {
        name: "21232f297a57a5a743894a0e4a801fc3",
        password: "e10adc3949ba59abbe56e057f20f883e",
      },
      active1: false,
      active2: false,
    };
  },
  created() {},
  methods: {
    ...mapMutations(["setLogin"]),
    handleSubmit() {
      if (!this.checkLoginForm()) return;
      this.setLogin(true);
      this.$toast({
        message: "正在前往...",
        duration: 1000,
      });
      setTimeout(() => {
        this.$router.replace("/");
      }, 1000);
    },
    checkLoginForm() {
      let { name, password } = this.loginForm;
      if (name == "") {
        this.$toast({
          message: "帳號不能為空！",
        });
        return false;
      }
      if (password == "") {
        this.$toast({
          message: "密碼不能為空！",
        });
        return false;
      }
      if (
        md5(name) != this.confirm.name ||
        md5(password) != this.confirm.password
      ) {
        this.$toast({
          message: "帳號或密碼有誤！",
        });
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./login.scss";
</style>