<template>
  <div :class="['select-pop', { active: showSelectPop }]">
    <div class="mask" @click="onCancel"></div>
    <div class="select-wrap">
      <!-- <div class="swiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in countryList"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div> -->
      <van-picker
        show-toolbar
        :confirm-button-text="btnTxt[0]"
        :cancel-button-text="btnTxt[1]"
        :columns="countryList"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </div>
  </div>
</template>

<script>
import { Picker } from "vant";
export default {
  props: {
    showSelectPop: {
      type: Boolean,
      default: false,
    },
    countryList: {
      require: true,
      type: Array,
    },
    btnTxt: {
      require: true,
      type: Array,
      default: ["确认", "取消"],
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    // var mySwiper = new Swiper(".swiper", {
    //   direction: "vertical",
    //   slidesPerView: 5,
    //   centeredSlides: true,
    //   //   centeredSlidesBounds: true,
    //   slideToClickedSlide: true,
    // });
  },
  methods: {
    onConfirm(value, index) {
      this.$emit("selectItem", value);
    },
    onCancel() {
      this.$emit("closePop", false);
    },
  },
  components: { [Picker.name]: Picker },
};
</script>

<style lang="scss" scoped>
.select-pop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  visibility: hidden;
  &.active {
    visibility: visible;
    .mask {
      opacity: 1;
    }
    .select-wrap {
      transform: translateY(0%);
    }
  }
  .mask {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: 0.3s;
  }
  .select-wrap {
    position: absolute;
    width: 100%;
    // height: 320px;
    background-color: #fff;
    left: 0;
    bottom: 0;
    transition: 0.5s;
    transform: translateY(100%);
    z-index: 1;
    padding: 20px 0;
    // .swiper {
    //   width: 100%;
    //   height: 100%;
    //   .swiper-slide {
    //     text-align: center;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     transition: 0.3s;
    //   }
    //   .swiper-slide-active {
    //     color: rgb(220, 37, 37);
    //     font-size: 20px;
    //     text-decoration: underline;
    //   }
    // }
    :deep() .van-picker {
      .van-ellipsis {
        overflow: visible;
      }
    }
  }
}
</style>