let picSource = {
    lee: [
        require("./assets/images/pic1.png"),
        require("./assets/images/lee.png"),
        require("./assets/images/logo.png"),
        require("./assets/images/prizm_logo.png"),

        require("./assets/images/image_map.png"),
        require("./assets/images/image_map _eng.png"),
        require("./assets/images/video_poster.png"),
        require("./assets/images/icon_gift.png"),
        require("./assets/images/lee_title.svg"),
        require("./assets/images/time.svg"),

        require("./assets/images/svg/icon_social_fb.svg"),
        require("./assets/images/svg/icon_social_ig.svg"),
        require("./assets/images/svg/icon_social_youtube.svg"),
    ]
}

let videoSource = {
    lee: require("./assets/video/Lee.mp4")
}

export {
    picSource, videoSource
}