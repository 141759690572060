<template>
  <div :class="['feedback', { active: showPop }]">
    <div class="mask"></div>
    <div class="feedback-wrap" :style="{ width: width, padding: padding }">
      <p class="txt">
        {{ networkTip.success }}
      </p>
      <div
        class="agree-btn"
        :style="{ width: btnWidth }"
        @click="$emit('close')"
      >
        {{ networkTip.btnTxt }}
      </div>
      <div class="close-btn" @click="$emit('close')"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showPop: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "90%",
    },
    padding: {
      type: String,
      default: "20px",
    },
    btnWidth: {
      type: String,
      default: "35%",
    },
    networkTip: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {};
  },
  created() {
    this.showCookiePop();
  },
  methods: {
    showCookiePop() {
      let time = localStorage.getItem("time");
      let nowDate = new Date();
      nowDate.setHours(0);
      nowDate.setMinutes(0);
      nowDate.setSeconds(0);
      nowDate.setMilliseconds(0);
      if (!time) {
        this.showPop = true;
        localStorage.setItem("time", nowDate.getTime());
      } else {
        if (time == nowDate.getTime()) {
          this.showPop = false;
        } else {
          this.showPop = true;
          localStorage.setItem("time", nowDate.getTime());
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  visibility: hidden;
  &.active {
    visibility: visible;
    .mask {
      opacity: 1;
    }
    .feedback-wrap {
      transform: translateY(0%) scale(1);
      opacity: 1;
    }
  }
  .mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: 0.2s;
  }
  .feedback-wrap {
    position: relative;
    z-index: 10;
    width: 90%;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px #ccc inset;
    transform: translateY(50%) scale(0);
    transition: 0.3s;
    opacity: 0;
    .txt {
      text-align: center;
      font-size: 16px;
      color: #878787;
      line-height: 30px;
      letter-spacing: 1px;
    }
    .agree-btn {
      cursor: pointer;
      width: 35%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffa130;
      // border-radius: 10px;
      color: #fff;
      margin: 20px auto 0;
      letter-spacing: 1px;
    }

    .close-btn {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateY(-30px);
      width: 30px;
      height: 30px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 2px;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: #fff;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}
</style>