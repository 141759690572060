import Vue from 'vue'
import Vuex from 'vuex'
import language from "../selectLan"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    fillTable: false,
    isLogin: false,
    default: "CN", // 默认CN （CN，EN）
    languageArr: ["CN", "EN"],
    language: language,
  },
  getters: {
    curLanguage(state) {
      return state.language[state.default];
    },
    isLogin(state) {
      return state.isLogin || sessionStorage.getItem("isLogin");
    },
    fillTable(state) {
      return state.fillTable
    }
  },
  mutations: {
    setLanguage(state, lan) {
      if (!lan) return
      state.default = lan
    },
    setLogin(state, bol) {
      state.isLogin = bol;
      sessionStorage.setItem("isLogin", bol)
    },
    setFillTable(state, bol) {
      state.fillTable = bol
    }
  },
  actions: {
  },
  modules: {
  }
})
