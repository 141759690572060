<template>
  <div class="notfound">
    <div class="content">
      <div class="svg-wrap">
        <svg
          t="1646363737012"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="4627"
        >
          <path
            d="M260.266667 789.333333c-21.333333 0-38.4-17.066667-38.4-38.4v-59.733333H38.4c-12.8 0-29.866667-8.533333-34.133333-21.333333-4.266667-17.066667-4.266667-29.866667 4.266666-42.666667l221.866667-294.4c8.533333-12.8 25.6-17.066667 42.666667-12.8 17.066667 4.266667 25.6 21.333333 25.6 38.4v256h34.133333c21.333333 0 38.4 17.066667 38.4 38.4s-17.066667 38.4-38.4 38.4H298.666667v59.733333c0 21.333333-17.066667 38.4-38.4 38.4z m-145.066667-179.2h106.666667V469.333333l-106.666667 140.8zM913.066667 742.4c-21.333333 0-38.4-17.066667-38.4-38.4v-59.733333h-183.466667c-12.8 0-29.866667-8.533333-34.133333-21.333334-8.533333-12.8-4.266667-29.866667 4.266666-38.4l221.866667-294.4c8.533333-12.8 25.6-17.066667 42.666667-12.8 17.066667 4.266667 25.6 21.333333 25.6 38.4v256h34.133333c21.333333 0 38.4 17.066667 38.4 38.4s-17.066667 38.4-38.4 38.4h-34.133333v59.733334c0 17.066667-17.066667 34.133333-38.4 34.133333zM768 567.466667h106.666667V426.666667L768 567.466667zM533.333333 597.333333c-46.933333 0-85.333333-25.6-119.466666-68.266666-29.866667-38.4-42.666667-93.866667-42.666667-145.066667 0-55.466667 17.066667-106.666667 42.666667-145.066667 29.866667-42.666667 72.533333-68.266667 119.466666-68.266666 46.933333 0 85.333333 25.6 119.466667 68.266666 29.866667 38.4 42.666667 93.866667 42.666667 145.066667 0 55.466667-17.066667 106.666667-42.666667 145.066667-34.133333 46.933333-76.8 68.266667-119.466667 68.266666z m0-362.666666c-55.466667 0-98.133333 68.266667-98.133333 149.333333s46.933333 149.333333 98.133333 149.333333c55.466667 0 98.133333-68.266667 98.133334-149.333333s-46.933333-149.333333-98.133334-149.333333z"
            fill="#978CFF"
            p-id="4628"
          ></path>
          <path
            d="M354.133333 691.2a162.133333 21.333333 0 1 0 324.266667 0 162.133333 21.333333 0 1 0-324.266667 0Z"
            fill="#E3E5FC"
            p-id="4629"
          ></path>
          <path
            d="M8.533333 832a162.133333 21.333333 0 1 0 324.266667 0 162.133333 21.333333 0 1 0-324.266667 0Z"
            fill="#E3E5FC"
            p-id="4630"
          ></path>
          <path
            d="M661.333333 797.866667a162.133333 21.333333 0 1 0 324.266667 0 162.133333 21.333333 0 1 0-324.266667 0Z"
            fill="#E3E5FC"
            p-id="4631"
          ></path>
        </svg>
      </div>
      <div class="txt">
        <p>抱歉！页面无法访问…</p>
        <p>别紧张，试试看返回首页</p>
      </div>
      <div class="btn" @click="toHome">返回首页</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "page404",
  data() {
    return {};
  },
  created() {
    this.$notify.clear();
  },
  mounted() {},
  methods: {
    toHome() {
      this.$router.replace("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.notfound {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: -70px;
    .svg-wrap {
      width: 40%;
      animation: move 1.5s linear infinite alternate;
      svg {
        width: 100%;
      }
    }
    .txt {
      margin-top: 15px;
      text-align: center;
      p:nth-child(1) {
        font-size: 18px;
        color: #000;
      }
      p:nth-child(2) {
        margin-top: 10px;
        font-size: 13px;
        color: #524d4d;
      }
    }
    .btn {
      margin-top: 15px;
      text-align: center;
      width: 24%;
      padding: 5px 0;
      border-radius: 50px;
      border: 1px solid #000;
      font-size: 13px;
      color: #000;
    }
  }
}
</style>