import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 样式重置
import "./assets/css/reset.css";

// css3动画库
import "animate.css";

// velocity
import "velocity-animate";
import "velocity-animate/velocity.ui";

Vue.prototype.velocity = Velocity;

// 开发环境下移动端调试VConsole
// import vconsole from "vconsole";
// import vconsole from "./assets/libs/vConsole.min.js";
import { isMobile, isWeiXin } from "./tool/tool";

if (process.env.NODE_ENV === "development") {
  if (isMobile() || isWeiXin()) {
    // new vconsole();
  }
  console.log("窗口宽高：", window.innerWidth, window.innerHeight);
  console.log("设备像素比：", window.devicePixelRatio);
}

// 阻止滚动条&监听横竖屏&适配
import { initDevice, getParams } from "./tool/tool";
initDevice();

// 全局挂载$toast
import { Toast } from 'vant';
Vue.use(Toast);

import { Notify } from 'vant';
Vue.use(Notify);

Vue.config.productionTip = false;

store.commit("setFillTable", getParams("subscription"));
if (store.state.languageArr.includes(getParams("language"))) {
  store.commit("setLanguage", getParams("language"));
}


const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

export default app;
