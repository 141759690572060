import request from "./request"
import Vue from "vue";
import store from "../store";

// 提交user
function keepUser(data) {
    return request({
        url: "/addinfo.php",
        method: "post",
        load: true,
        data: new URLSearchParams(data)
    }).then((data) => {
        if (data.msgcode == 112 || data.msgcode == 221) {
            // Vue.prototype.$toast({
            //     duration: 1500,
            //     message: store.getters.curLanguage.networkTip.success,
            //     forbidClick: true
            // });
            return data
        } else {
            let msg = data.msg
            if (data.msgcode == 221) {
                msg = store.getters.curLanguage.networkTip.success;
            }
            Vue.prototype.$toast({
                duration: 1500,
                message: msg,
                forbidClick: true
            });
            return Promise.reject(data)
        }
    }, (err) => {
        return Promise.reject(err)
    })
}

export {
    keepUser
}