function toTwo(num) {
  num = num < 10 ? "0" + num : num;
  return num.toString();
}

// 图片预加载
function preLoadImg(target, onProgress, onComplete) {
  let ratio = 0,
    hasLoad = 0,
    sum = 0;
  sum = target.length || Object.keys(target).length;

  if (target instanceof Array) {
    target.forEach((url, index) => {
      let img = new Image();
      loadImg(img, url, index);
    });
  } else {
    for (let [key, url] of Object.entries(target)) {
      let img = new Image();
      loadImg(img, url, key);
    }
  }

  function loadImg(img, url, key) {
    img.src = url;
    img.addEventListener("load", () => {
      target[key] = img;
      ++hasLoad;
      ratio = (hasLoad / sum).toFixed(2) - 0;
      if (hasLoad >= sum) {
        ratio = 1;
      }
      onProgress && onProgress(ratio);
      if (hasLoad >= sum && onComplete) {
        onComplete();
      }
    });
    img.addEventListener("error", () => { });
  }
}

// 音频预加载
function preLoadAudio(target, onProgress, onComplete) {
  let ratio = 0,
    hasLoad = 0,
    sum = 0;
  sum = target.length || Object.keys(target).length;

  if (target instanceof Array) {
    target.forEach((url, index) => {
      let audio = document.createElement("audio");
      loadAudio(audio, url, index);
    });
  } else {
    for (let [key, url] of Object.entries(target)) {
      let audio = document.createElement("audio");
      loadAudio(audio, url, key);
    }
  }

  function loadAudio(audio, url, key) {
    audio.src = url;
    audio.load();
    audio.addEventListener("canplaythrough", () => {
      target[key] = audio;
      ++hasLoad;
      ratio = (hasLoad / sum).toFixed(2) - 0;
      if (hasLoad >= sum) {
        ratio = 1;
      }
      onProgress && onProgress(ratio);
      if (hasLoad >= sum && onComplete) {
        onComplete();
      }
    });
  }
}

function isArray(param) {
  return param instanceof Array;
}

function isObject(param) {
  return Object.prototype.toString.call(param) === '[object Object]';
}

//获取类似结构的总长度 arr = [ [], [] ] arr = [ {}, {} ]  obj = { a:[], b:[] } obj = { a:{}, b:{} } 
function getLength(strucData, callBack) {
  if (typeof strucData == null && typeof strucData != "object") {
    throw new Error("请按照如下数据结构 arr = [ [], [] ] arr = [ {}, {} ]  obj = { a:[], b:[] } obj = { a:{}, b:{} }")
  }
  let length = 0;

  function fn(data) {
    if (isArray(data)) {
      data.forEach((item, index) => {
        if (isArray(item) || isObject(item)) {
          fn(item)
        }
        if (typeof item === "string") {
          callBack && callBack()
        }
      })
    }

    if (isObject(data)) {
      for (let key in data) {
        let value = data[key];
        if (isArray(value) || isObject(value)) {
          fn(value)
        }
        if (typeof value === "string") {
          callBack && callBack()
        }
      }
    }
  }

  fn(strucData)

  return length

}

// 图片音频预加载
function preLoadResource(picTarget = [], audioTarget = [], onProgress, onComplete) {
  let ratio = 0,
    hasLoad = 0,
    sum = 0;
  // sum = getLength(picTarget)+getLength(audioTarget);

  function fn(data, callBack) {
    if (isArray(data)) {
      data.forEach((item, index) => {
        if (isArray(item) || isObject(item)) {
          fn(item, callBack)
        }
        if (typeof item === "string") {
          sum += 1;
          callBack && callBack(item, index, data)
        }
      });
    }

    if (isObject(data)) {
      for (let key in data) {
        let value = data[key]
        if (isArray(value) || isObject(value)) {
          fn(value, callBack)
        }
        if (typeof value === "string") {
          sum += 1;
          callBack && callBack(value, key, data)
        }
      }
    }
  }
  fn(picTarget, (url, key, data) => {
    let img = new Image();
    loadImg(img, url, key, data);
  })

  fn(audioTarget, (url, key, data) => {
    let audio = document.createElement("audio");
    loadAudio(audio, url, key, data);
  })

  // 保证数据都是load完的可用
  function loadImg(img, url, key, data) {
    img.src = url;
    img.addEventListener("load", () => {
      data[key] = img;
      ++hasLoad;
      ratio = (hasLoad / sum).toFixed(2) - 0;
      if (hasLoad >= sum) {
        ratio = 1;
      }
      onProgress && onProgress(ratio);
      if (hasLoad >= sum && onComplete) {
        onComplete();
      }
    });
    img.addEventListener("error", () => { });
  }

  function loadAudio(audio, url, key, data) {
    audio.src = url;
    audio.load();
    audio.addEventListener("canplaythrough", () => {
      data[key] = audio;
      ++hasLoad;
      ratio = (hasLoad / sum).toFixed(2) - 0;
      if (hasLoad >= sum) {
        ratio = 1;
      }
      onProgress && onProgress(ratio);
      if (hasLoad >= sum && onComplete) {
        onComplete();
      }
    });
  }
}

// 跳转外链
function jumpUrl(url) {
  if (typeof url === "string" && url.trim() !== "") window.location.href = url;
}

// 时间格式化（考虑iphone的时间格式）
function formatDate(time, geshi = "yyyy-mm-dd hh:ff:ss") {
  if (typeof time == "string") {
    time = time.replace(/-/g, "/");
  }
  let newTime = new Date(time);
  let y = newTime.getFullYear();
  let m = toTwo(newTime.getMonth() + 1);
  let d = toTwo(newTime.getDate());
  let h = toTwo(newTime.getHours());
  let f = toTwo(newTime.getMinutes());
  let s = toTwo(newTime.getSeconds());
  return geshi
    .replace("yyyy", y)
    .replace("mm", m)
    .replace("dd", d)
    .replace("hh", h)
    .replace("ff", f)
    .replace("ss", s);
}

// 手机号脱敏
function dealPhone(phone) {
  return phone.replace(/(\d{3})\d{4}(\d{4})/g, "$1****$2");
}

// 抓取参数/抓取特定的参数
function getParams(paramsKey) {
  let search = location.href.split("?")[1] && location.href.split("?")[1].split("#")[0];
  let params = {};
  if (search) {
    search = search.replace("?", "");
    let paramsArr = search.split("&");
    paramsArr.forEach((item, index) => {
      let paramsItem = item.split("=");
      params[paramsItem[0]] = paramsItem[1];
    });
    if (paramsKey) {
      for (let key in params) {
        if (key == paramsKey) {
          return params[key];
        }
      }
    } else {
      return params;
    }
  }
}

// 倒计时（返回天、时、分、秒）
function countDown(time, timeObj, callBack) {
  let futureTime = null;
  let offsetTime = null;
  let dd = "00";
  let hh = "00";
  let ff = "00";
  let mm = "00";
  if (typeof time == "string") {
    time = time.replace(/-/g, "/");
    futureTime = new Date(time).getTime();
  } else {
    futureTime = new Date(time).getTime();
  }
  let nowDate = new Date().getTime();
  if (nowDate <= futureTime) {
    offsetTime = futureTime - nowDate;
    dd = toTwo(parseInt(offsetTime / 1000 / 60 / 60 / 24));
    hh = toTwo(parseInt((offsetTime / 1000 / 60 / 60) % 24));
    ff = toTwo(parseInt((offsetTime / 1000 / 60) % 60));
    mm = toTwo(parseInt((offsetTime / 1000) % 60));
    timeObj.dd = dd;
    timeObj.hh = hh;
    timeObj.ff = ff;
    timeObj.mm = mm;
  } else {
    timeObj.isOver = true;
    callBack && callBack();
    return;
  }
  let timer = setInterval(() => {
    nowDate = new Date().getTime();
    if (nowDate <= futureTime) {
      offsetTime = futureTime - nowDate;
      dd = toTwo(parseInt(offsetTime / 1000 / 60 / 60 / 24));
      hh = toTwo(parseInt((offsetTime / 1000 / 60 / 60) % 24));
      ff = toTwo(parseInt((offsetTime / 1000 / 60) % 60));
      mm = toTwo(parseInt((offsetTime / 1000) % 60));
      timeObj.dd = dd;
      timeObj.hh = hh;
      timeObj.ff = ff;
      timeObj.mm = mm;
      return timeObj;
    } else {
      timeObj.isOver = true;
      callBack && callBack();
      clearInterval(timer);
    }
  }, 1000);
}

// 阻止滚动条（方案一）
function initWindowPage1(win = window) {
  dealScreenDirection();
  function initPage() {
    // 兼容iphone，android
    const offset = 0.1;
    let winWidth = win.innerWidth;
    let winheight = win.innerHeight + offset;

    let html = document.documentElement;
    let body = html.body || document.body;
    html.style.width = `${winWidth}px`;
    html.style.height = `${winheight}px`;

    body.style.width = `${winWidth}px`;
    body.style.height = `${winheight}px`;
  }
  initPage();
  // win.addEventListener("resize", initPage);
}

// 阻止滚动条（方案二更美观）
function initWindowPage2(win = window) {
  // dealScreenDirection();
  let head = document.querySelector("head");
  let firstStyle = document.querySelector("style");
  let newStyle = null;
  function initPage() {
    // 兼容iphone，android
    const offset = 0.1;
    let winWidth = win.innerWidth;
    let winheight = win.innerHeight + offset;

    let html = document.documentElement;
    let body = html.body || document.body;

    if (newStyle) {
      newStyle.remove()
    }
    newStyle = document.createElement("style");
    let InitStyle = `
    html {
      width: ${winWidth}px !important;
      height: ${winheight}px !important;
      overflow: auto;
    }
    body {
      width: ${winWidth}px !important;
      height: ${winheight}px !important;
      overflow: auto;
    } 
  `;
    newStyle.setAttribute("type", "text/css");
    newStyle.textContent = InitStyle;
    head.insertBefore(newStyle, firstStyle);
  }
  if (isMobile()) {
    initPage()
  }
}

// 是否为移动端（true：移动端，false：pc端）
function isMobile() {

  let deviceInfo = window.navigator.userAgent;
  let reg = /(Mobile)/gi;
  return reg.test(deviceInfo);
}

// 自定义pc和移动端
function isDIYMobile() {
  let winWidth = window.innerWidth;
  return isMobile() || winWidth < 1000
}

// 是否是Android系统（true：Android，false：ios）
function isAndroid() {
  let deviceInfo = window.navigator.userAgent;
  let reg = /(Android)/gi;
  return reg.test(deviceInfo);
}

// 是否是微信浏览器
function isWeiXin() {
  let deviceInfo = window.navigator.userAgent;
  let reg = /MicroMessenger/gi;
  return reg.test(deviceInfo);
}

// 是否为window系统（true：window，false: Mac）
function isWindow() {
  let deviceInfo = window.navigator.userAgent;
  let reg = /(Windows)/gi;
  return reg.test(deviceInfo);
}

// 是否全屏状态
function isFullScreen() {
  return !!document.fullscreenElement;
}

// 开启全屏
function requestFullScreen() {
  let html = document.documentElement;
  return new Promise((resolve, reject) => {
    if (html.requestFullscreen) {
      html.requestFullscreen().then(
        (success) => {
          resolve(success);
        },
        (error) => {
          reject(error);
        }
      );
    } else if (html.mozRequestFullScreen) {
      html.mozRequestFullScreen().then(
        (success) => {
          resolve(success);
        },
        (error) => {
          reject(error);
        }
      );
    } else if (html.webkitRequestFullScreen) {
      html.webkitRequestFullScreen().then(
        (success) => {
          resolve(success);
        },
        (error) => {
          reject(error);
        }
      );
    } else {
      reject();
    }
  });
}

// 退出全屏
function exitFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
}

// 是否竖屏（移动端特有phone、ipad）
function dealScreenDirection() {
  window.addEventListener("orientationchange", () => {
    if (!isMobile()) return;
    location.reload();
  });
}

// 适配
function init(deviceWidth = 375) {
  function adaptDevice() {

    let html = document.documentElement;
    let winWidth = window.innerWidth;
    html.removeAttribute("style")
    if (!isDIYMobile()) return
    let body = document.documentElement.body || document.body;
    let div = document.createElement("div");


    div.style.width = "1rem";
    body.appendChild(div);
    let defaultSize = Number(window.getComputedStyle(div).width) || 16;
    // 计算出比率并保留两位小数
    let ratio = (defaultSize / (deviceWidth / 100)).toFixed(2) - 0;
    div.remove();
    if (isMobile()) {
      // 移动端
      let perVW = winWidth / 100;
      let fontSize = (perVW * ratio).toFixed(1) - 0;
      html.style.fontSize = `${fontSize}px`;
    } else {
      // pc端
      let perVW = winWidth / 150;
      let fontSize = (perVW * ratio).toFixed(1) - 0;
      if (fontSize <= 16) fontSize = 16
      html.style.fontSize = `${fontSize}px`;
    }
  }
  adaptDevice();
}

function initDevice() {
  let curPlatform = isDIYMobile() ? "m" : "p";
  let flag = isMobile();
  let timer;
  initWindowPage2()
  init()

  window.addEventListener("resize", () => {
    initWindowPage2()
    init()
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      if (flag != isMobile()) {
        flag = isMobile()
        window.location.href = ""
      }
      if (!isMobile()) {
        let nPlatForm = isDIYMobile() ? "m" : "p";
        console.log(nPlatForm)
        if (nPlatForm != curPlatform) {
          curPlatform = nPlatForm;
          window.location.href = ""
        }
      }

    }, 20)
  })
}

export {
  preLoadImg,
  preLoadAudio,
  preLoadResource,
  jumpUrl,
  formatDate,
  dealPhone,
  getParams,
  countDown,
  initWindowPage1,
  initWindowPage2,
  isMobile,
  isDIYMobile,
  isAndroid,
  isWeiXin,
  isWindow,
  isFullScreen,
  requestFullScreen,
  exitFullscreen,
  dealScreenDirection,
  init,
  initDevice
};
